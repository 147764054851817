const Link = {
    variants: {
        subtle: {
            _hover: {
                textDecoration: 'none',
            },
            color: 'gray.800',
        },
    },
};

export default Link;
