const Divider = {
    baseStyle: {
        borderColor: 'gray.800',
        borderStyle: 'solid',
        borderWidth: '2px',
        opacity: 1,
    },
};

export default Divider;
